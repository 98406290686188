export class Platform {

}

export enum PlatformEnum {
    FACEBOOK = "FACEBOOK",
    INSTAGRAM = "INSTAGRAM",
    LINKEDIN = "LINKEDIN"
}

export const PlatformKeyValue = Object.entries(PlatformEnum).map(([key, value]) => ({ key: value, value: key }))
